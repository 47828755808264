import { useState, useRef } from 'react';
import { useDrop } from 'react-dnd';

import {
  FolderUp,
  Image,
  MessagesSquare,
  SendHorizontal,
  Video
} from 'lucide-react';

import Bubble from './components/Bubble';

import {
  getRandomXYStyle,
  getTimestampedText
} from './utils';

import './App.css';

const App = () => {
  const defaultWorkspace = JSON.parse(
    sessionStorage.getItem('FH_WORKSPACE')
  ) || {};

  const defaultSessionId = (
    sessionStorage.getItem('FH_SESSION_ID')
  );

  const randomSessionId = (
    defaultSessionId || window.crypto.randomUUID()
  );

  const { API_URL } = window;

  const [sessionId, setSessionId] = useState(randomSessionId);
  const [workspace, setWorkspace] = useState({});
  const [isTyping, setIsTyping] = useState(false);
  const [placeholder, setPlaceholder] = useState('Type a prompt...');
  const [disableNew, setDisableNew] = useState(true);
  const [input, setInput] = useState('');
  const [output, setOutput] = useState(defaultWorkspace[randomSessionId]?.messages || []);

  const chat = async () => {
    const updatedOutput = [
      ...output,

      getTimestampedText(input)
    ];

    setInput('');
    setOutput(updatedOutput);
    setIsTyping(true);

    const response = await fetch(`${API_URL}/txt2img`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        sessionId,
        input
      })
    });

    setIsTyping(false);
    setDisableNew(false);

    if (response?.ok) {
      setPlaceholder('Extend the prompt...');

      const result = await response.json();

      if (result?.success) {
        const nextUpdatedOutput = [
          ...updatedOutput,

          `<img alt="AI generated image" src="${result.imgResponse}" width=${result.width} width=${result.height} />`
        ];

        setOutput(nextUpdatedOutput);

        if (workspace[sessionId]) {
          const updatedWorkspace = {
            ...workspace,

            [sessionId]: {
              ...workspace[sessionId],

              messages: nextUpdatedOutput
            }
          };

          setWorkspace(updatedWorkspace);
        }
      }
    }
  };

  const onChange = ({ target: { value }}) => setInput(value);

  const onKeyDown = ({ keyCode }) => {
    if (keyCode === 13) {
      return chat();
    }
  };

  const onClickNewChat = () => {
    if (disableNew) return;

    setInput('');
    setOutput([]);
    setPlaceholder('Type a prompt...');
    setSessionId(window.crypto.randomUUID());
    setDisableNew(true);

    const updatedWorkspace = {
      ...workspace,

      [sessionId]: {
        style: getRandomXYStyle(),
        messages: output
      }
    };

    if (sessionId) {
      setWorkspace(updatedWorkspace);
      sessionStorage.setItem('FH_WORKSPACE', JSON.stringify(updatedWorkspace));
      sessionStorage.setItem('FH_SESSION_ID', sessionId);
    }
  };

  const onClickBubble = id => () => {
    if (!disableNew) {
      const cachedWorkspace = workspace[sessionId] || {
        style: getRandomXYStyle(),
        messages: output
      };

      const updatedWorkspace = {
        ...workspace,

        [sessionId || window.crypto.randomUUID()]: cachedWorkspace
      };

      setWorkspace(updatedWorkspace);
      sessionStorage.setItem('FH_WORKSPACE', JSON.stringify(updatedWorkspace));
      sessionStorage.setItem('FH_SESSION_ID', sessionId);
    }

    setSessionId(id);
    setInput('');
    setOutput(workspace[id].messages);
    setDisableNew(false);
  };

  const onDropBubble = (item, monitor) => {
    if (!Object.keys(workspace)?.length) return;

    const { x, y } = monitor.getSourceClientOffset();
    const bubble = workspace[item.id];

    const updatedWorkspace = {
      ...workspace,

      [item.id]: {
        ...bubble,

        style: {
          ...bubble.style,

          left: x,
          top: y
        }
      }
    };

    setWorkspace(updatedWorkspace);
    sessionStorage.setItem('FH_WORKSPACE', JSON.stringify(updatedWorkspace));
    sessionStorage.setItem('FH_SESSION_ID', sessionId);
  };

  const onCollectBubble = monitor => ({
    isOver: monitor.isOver()
  });

  const dropRef = element => {
    drop(element);

    if (element) {
      boundingBox.current = element.getBoundingClientRect();
    }
  };

  const boundingBox = useRef(null);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'Bubble',
    drop: onDropBubble,
    collect: onCollectBubble
  }), [workspace]);

  return (
    <div className="relative bg-black flex w-[100vw] h-[100vh] p-4 gap-4 text-white overflow-hidden">
      {Object.keys(workspace).map(key => {
        const {
          style,
          messages
        } = workspace[key];

        return (
          <Bubble
            key={key}
            id={key}
            style={{
              ...style,

              ...(sessionId !== key
                ? {
                  filter: 'saturate(0)'
                }
                : {}
              )
            }}
            onClick={onClickBubble(key)}
          >
            {messages[messages.length - 1]}
          </Bubble>
        );
      })}
      <aside className="flex flex-col items-center gap-6">
        <a href="https://www.fasterhorses.xyz">
          <img className="mt-7" src="/logo512.png" alt="Faster Horses" width={32} height={32} />
        </a>
        <a className="opacity-50" href="https://chat.fasterhorses.xyz">
          <MessagesSquare strokeWidth={1} />
        </a>
        <a className="opacity-100"  href="https://txt2img.fasterhorses.xyz">
          <Image strokeWidth={1} />
        </a>
        <a className="opacity-50"  href="https://rag.fasterhorses.xyz">
          <FolderUp strokeWidth={1} />
        </a>
        <a disabled className="opacity-50 cursor-not-allowed" href="">
          <Video strokeWidth={1} />
        </a>
      </aside>
      <section id="chat" ref={dropRef} className="flex flex-col justify-between w-full h-full p-4 gap-4 text-sm">
        <div id="output" className="bg-[#222] w-full h-[calc(100%-3.5rem)] p-4 rounded-lg overflow-y-scroll">
          <header className="flex justify-end pb-4 mb-4 border-b border-[#333]">
            <button
              disabled={disableNew}
              className="text-[dodgerblue]"
              onClick={onClickNewChat}
            >
              New Prompt
            </button>
          </header>
          <p className="text-xs opacity-50">{`${new Date()}\n\n`}</p>
          {output.map(message => (
            <p key={message} className="border-b border-[#444] mt-6" dangerouslySetInnerHTML={{
              __html: message.replace(/\n/g, '<br />')
            }} />
          ))}
          {isTyping && <div className="flex items-center gap-2">
            <div role="status">
              <svg aria-hidden="true" className="w-4 h-4 my-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
              <span className="sr-only">Loading...</span>
            </div>
            <em className="text-xs opacity-50">Generating...</em>
          </div>}
        </div>
        <div id="input" className="flex gap-4 w-full h-[3.5rem]">
          <input
            disabled={isOver}
            className="bg-[#222] flex items-center w-full h-full p-4 rounded-lg outline-none"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={input}
            placeholder={placeholder}
          />
          <button
            disabled={isOver}
            className="flex items-center justify-center font-bold text-center"
            onClick={chat}
          >
            <SendHorizontal strokeWidth={1} />
          </button>
        </div>
      </section>
    </div>
  );
}

export default App;
